<template>
  <pp-section header="LP Overview">
    <pp-card class="overflow-auto">
      <pp-table class="min-w-[600px]">
        <thead>
          <pp-th>Name</pp-th>
          <pp-th align="right">Balance</pp-th>
          <pp-th align="right">
            <div>Yield</div>
            <div class="text-sm font-normal">Accrued</div>
          </pp-th>
        </thead>

        <tbody v-if="loading">
          <pp-tr>
            <pp-td colspan="100">
              <pp-skeleton-loader class="h-8"></pp-skeleton-loader>
            </pp-td>
          </pp-tr>
        </tbody>

        <tbody v-else>
          <pp-tr v-if="balances.length === 0">
            <pp-td colspan="100">No balance available.</pp-td>
          </pp-tr>

          <template v-else v-for="balance in balancesMeta">
            <pp-tr
              :tabindex="expandable ? '1' : undefined"
              :role="expandable ? 'button' : undefined"
              @click="balance.expanded = !balance.expanded"
              @keypress.enter="balance.expanded = !balance.expanded"
              @keypress.space="balance.expanded = !balance.expanded"
              :key="balance.tokenAmount.token.address"
              :data-test="`${balance.tokenAmount.token.address}`"
              :class="{
                'text-p-yellow-800': balance.composition.stakingPool.some(
                  (pool) => pool.source.disabled
                ),
                'w-auto align-top outline-none transition focus:ring-2 ring-offset-2 ring-p-blue-600':
                  expandable,
              }"
            >
              <pp-td>
                <pp-token-preview :token="balance.tokenAmount.token" :show-indicator="true">
                  <template #status-indicator>
                    <pp-market-status-indicator
                      class="ml-2"
                      :address="balance.tokenAmount.token.address"
                    ></pp-market-status-indicator>
                  </template>
                </pp-token-preview>

                <div v-if="balance.composition.stakingPool.some((pool) => pool.source.disabled)">
                  <pp-pill size="xs" variant="accent">Old</pp-pill>
                </div>
              </pp-td>

              <pp-td align="right">
                <div>
                  <pp-formatted-price
                    data-test="value"
                    :currency="balance.valuation.currency"
                    :value="balance.valuation.amount"
                  ></pp-formatted-price>
                </div>

                <div class="text-sm opacity-70">
                  <pp-formatted-number
                    data-test="percent-staked"
                    :value="
                      ((+balance.tokenAmount.formattedAmount() -
                        +balance.composition.wallet.tokenAmount.formattedAmount()) /
                        balance.tokenAmount.formattedAmount()) *
                      100
                    "
                  ></pp-formatted-number>
                  % staked
                </div>
              </pp-td>

              <pp-td align="right">
                <div class="space-y-4">
                  <div v-for="_yield in balance.yields" :key="_yield.tokenAmount.token.address">
                    <pp-token-amount
                      :token-amount="_yield.tokenAmount"
                      data-test="yield-quantity"
                    ></pp-token-amount>

                    <div class="opacity-70 text-sm">
                      <pp-formatted-price
                        data-test="yield-value"
                        :currency="_yield.valuation.currency"
                        :value="_yield.valuation.amount"
                      ></pp-formatted-price>
                    </div>
                  </div>
                </div>
              </pp-td>
            </pp-tr>

            <pp-scale-transition
              v-if="expandable"
              origin="left"
              :key="`expansion--${balance.tokenAmount.token.address}`"
            >
              <keep-alive>
                <LpOverviewItemExpansion
                  v-if="balance.expanded"
                  :token="balance.tokenAmount.token"
                />
              </keep-alive>
            </pp-scale-transition>
          </template>
        </tbody>
      </pp-table>
    </pp-card>
  </pp-section>
</template>

<script>
  import Vue from 'vue'
  import TokenAmount from '@/domains/entities/TokenAmount'
  import QueryCollection from '@/domains/QueryCollection'
  import Valuation from '@/domains/Valuation'
  import LpOverviewItemExpansion from '@/components/dashboard/LpOverviewItemExpansion.vue'

  export default Vue.extend({
    components: {
      LpOverviewItemExpansion,
    },
    props: {
      walletBalances: { type: Array, default: () => [] },
      stakingPoolBalances: { type: Array, default: () => [] },
      yields: { type: Array, default: () => [] },
      tokens: { type: Array, default: () => [] },
      loading: { type: Boolean, default: false },
      expandable: { type: Boolean, default: true },
    },
    data() {
      return {
        balancesMeta: [],
      }
    },
    watch: {
      balances() {
        this.balancesMeta = this.balances.map((balance) => ({
          ...balance,
          expanded: false,
        }))
      },
    },
    computed: {
      balances() {
        const { walletBalances, stakingPoolBalances } = this
        const balances = [walletBalances, stakingPoolBalances].flat()

        return this.tokens
          .toArray()
          .map((token) => {
            const tokenBalances = balances.filter(
              (balance) => balance.tokenAmount.token.address === token.address
            )

            return {
              tokenAmount: tokenBalances.reduce(
                (acc, balance) => acc.plus(balance.tokenAmount),
                new TokenAmount(token, 0)
              ),
              valuation: tokenBalances.reduce(
                (acc, balance) => acc.plus(balance.valuation),
                new Valuation({ currency: 'USD', amount: 0 })
              ),
              composition: {
                wallet: new QueryCollection(...walletBalances)
                  .where('tokenAmount.token.address', token.address)
                  .reduce(
                    (acc, balance) => ({
                      tokenAmount: acc.tokenAmount.plus(balance.tokenAmount),
                      valuation: acc.valuation.plus(balance.valuation),
                    }),
                    {
                      tokenAmount: new TokenAmount(token, 0),
                      valuation: new Valuation({ currency: 'USD', amount: 0 }),
                    }
                  ),
                stakingPool: new QueryCollection(...stakingPoolBalances)
                  .where('tokenAmount.token.address', token.address)
                  .where('tokenAmount', (val) => val.formattedAmount() > 0),
              },
              yields: Object.values(
                this.yields
                  .filter((yieldMeta) => yieldMeta.origin.token.address === token.address)
                  .reduce((acc, yieldMeta) => {
                    acc[yieldMeta.yield.tokenAmount.token.address] =
                      acc[yieldMeta.yield.tokenAmount.token.address] || []
                    acc[yieldMeta.yield.tokenAmount.token.address].push(yieldMeta.yield)
                    return acc
                  }, {})
              )
                .map((yields) =>
                  yields.reduce(
                    (acc, _yield) => ({
                      tokenAmount: acc.tokenAmount.plus(_yield.tokenAmount),
                      valuation: acc.valuation.plus(_yield.valuation),
                    }),
                    {
                      tokenAmount: new TokenAmount(yields[0].tokenAmount.token, 0),
                      valuation: new Valuation({ currency: 'USD', amount: 0 }),
                    }
                  )
                )
                .filter((_yield) => _yield.tokenAmount.token.name !== 'PENDLE'),
            }
          })
          .filter(
            (balance) =>
              balance.tokenAmount.formattedAmount() > 0 ||
              balance.yields.reduce(
                (acc, _yield) => acc + _yield.tokenAmount.formattedAmount(),
                0
              ) > 0
          )
      },
    },
  })
</script>
