<template>
  <pp-section header="Epoch Countdown">
    <template v-slot:header>
      <div class="flex items-center flex-wrap">
        <div>
          <div class="flex items-center flex-wrap">
            <h2 class="font-semibold text-2xl">Epoch Countdown</h2>
            <pp-tooltip-trigger class="ml-2">
              Countdown to the start of next epoch
            </pp-tooltip-trigger>
          </div>
          <div>
             <pp-relative-time
              class="font-semibold"
              format="countdown"
              :value="new Date(1000 * currentEpochTime.response)"
            ></pp-relative-time>
          </div>
          <div>
            Epoch ends on
            <pp-skeleton-loader
              v-if="currentEpochTime.is('loading')"
              class="inline-block w-24"
            ></pp-skeleton-loader>
            <pp-formatted-date
              v-else-if="currentEpochTime.is('resolved')"
              :value="new Date(1000 * currentEpochTime.response)"
              class="font-semibold"
            ></pp-formatted-date>
          </div>
        </div>
      </div>
    </template>
  </pp-section>
</template>

<script>
  import Vue from 'vue'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import Token from '@/domains/entities/Token'
  import { getCurrentEpochEndTime } from '@pendle/sdk'
  import { sdkChainId } from '@/app-config/network'

  export default Vue.extend({
    data() {
      return {
        currentEpochTime: createState({ response: 0 }),
      }
    },
    computed: {
      pendleToken() {
        return Token.query()
          .where('chainId', this.$store.state.wallet.networkId)
          .findBy('name', 'PENDLE')
      },
    },
    methods: {
      getCurrentEpochEndTime() {
        const { signer } = this.$store.getters['wallet/identity']
        return new PromiseHandler(
          () => getCurrentEpochEndTime(signer, sdkChainId),
          this.currentEpochTime
        ).execute()
      },
    },
    created() {
      this.getCurrentEpochEndTime()
    },
  })
</script>
