import { PendleMarket as SdkPendleMarket } from '@pendle/sdk'
import { sdkChainId } from '@/app-config/network'
import Token from '@/domains/entities/Token'
import TokenAmount from '@/domains/entities/TokenAmount'
import Wallet from '@/domains/entities/Wallet'

export default class PendleAmmLpTokenCollectionContract {
  constructor({ tokenCollection, provider, signer }) {
    this.tokenCollection = tokenCollection
    this.provider = provider
    this.signer = signer
  }

  fetchInterests(wallet) {
    return new Promise((resolve, reject) => {
      SdkPendleMarket.methods({
        signer: this.signer,
        provider: this.provider,
        chainId: sdkChainId,
      })
        .fetchInterests(wallet)
        .then((response) => {
          resolve(
            response.map((interest) => ({
              origin: {
                token: Token.query().find(interest.address),
                source: new Wallet({ address: this.wallet }),
              },
              yields: [
                {
                  yield: TokenAmount.fromSdkTokenAmount(interest.interest),
                  yieldType: 'interest',
                  acquisitionMethod: 'accrue',
                },
              ],
            }))
          )
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
