<template>
  <div class="flex flex-wrap">
    <GasSaverNotice />
    <div class="w-full lg:w-[320px] space-y-8">
      <PortfolioValuation
        :loading="
          [walletBalances, stakingPoolBalances, yields, distributedRewards].some((query) =>
            query.is('loading')
          )
        "
        :wallet="walletBalances.response"
        :staking-pools="stakingPoolBalances.response"
        :yields="allYields.map((yieldItem) => yieldItem.yield)"
      />

      <pp-card>
        <pp-card-body>
          <pp-simple-data class="-mt-2 text-lg !items-start">
            <template #left>PENDLE Price</template>

            <template #right>
              <div class="text-right">
                <div>
                  <pp-token-amount-valuation
                    data-test="token-price"
                    class="font-medium"
                    :token-amount="new TokenAmount(pendleToken, 1, false)"
                  ></pp-token-amount-valuation>
                </div>

                <pp-obtain-token-link
                  v-if="pendleToken.sources[0]"
                  :address="pendleToken.address"
                ></pp-obtain-token-link>
              </div>
            </template>
          </pp-simple-data>
        </pp-card-body>
      </pp-card>

      <PendleRewardSection
        v-if="isMainnet"
        @success="$emit('success', $event)"
        :token-amount="pendleReward.response"
        :loading="pendleReward.is('first-load')"
      />

      <InterestsAndRewards
        @success="$emit('success', $event)"
        :yields="allYields"
        :loading="yields.is('first-load') && distributedRewards.is('first-load')"
      />

      <EpochCountdown v-if="isMainnet" />
      <PendleVestingTable v-else :staking-pools="stakingPools" />
    </div>

    <div class="w-full mt-8 lg:mt-0 lg:ml-8 lg:flex-1 space-y-8">
      <YoOverviewSection
        header="YT Overview"
        :balances="
          QueryCollection.from(walletBalances.response).where(
            'tokenAmount.token.tokenCategories',
            (val) => val.includes('yt')
          )
        "
        :yields="walletYields"
        :loading="walletBalances.is('loading')"
      />

      <YoOverviewSection
        header="OT Overview"
        :yields="walletYields"
        :balances="
          QueryCollection.from(walletBalances.response).where(
            'tokenAmount.token.tokenCategories',
            (val) => val.includes('ot')
          )
        "
        :loading="walletBalances.is('loading')"
      />

      <LpOverviewSection
        :wallet-balances="walletBalances.response"
        :staking-pool-balances="stakingPoolBalances.response"
        :yields="yields.response"
        :tokens="pendleLps"
        :loading="walletBalances.is('loading')"
        :expandable="lpOverviewExpandable"
      />

      <StakeOverviewSection
        :staking-pool-balances="stakingPoolBalances.response"
        :yields="yields.response"
        :loading="stakingPoolBalances.is('loading')"
      />
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import PendleAmmLpToken from '@/domains/entities/PendleAmmLpToken'
  import PendleLpToken from '@/domains/entities/PendleLpToken'
  import OtLpToken from '@/domains/entities/OtLpToken'
  import YT from '@/domains/entities/YT'
  import OT from '@/domains/entities/OT'
  import StakingPool from '@/domains/entities/StakingPool'
  import Token from '@/domains/entities/Token'
  import TokenAmountCollection from '@/domains/collections/TokenAmountCollection'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import { sdkChainId } from '@/app-config/network'
  import { Sdk, UniForkMarket } from '@pendle/sdk'
  import TokenAmount from '@/domains/entities/TokenAmount'
  import Valuation from '@/domains/Valuation'
  import Wallet from '@/domains/entities/Wallet'
  import PendleReward from '@/domains/entities/PendleReward'
  import QueryCollection from '@/domains/QueryCollection'
  import PortfolioValuation from '@/components/dashboard/PortfolioValuation.vue'
  import InterestsAndRewards from '@/components/dashboard/InterestsAndRewards.vue'
  import PendleRewardSection from '@/components/dashboard/PendleRewardSection.vue'
  import EpochCountdown from '@/components/dashboard/EpochCountdown.vue'
  import PendleVestingTable from '@/components/dashboard/PendleVestingTable.vue'
  import YoOverviewSection from './YoOverviewSection.vue'
  import LpOverviewSection from './LpOverviewSection.vue'
  import StakeOverviewSection from './StakeOverviewSection.vue'
  import GasSaverNotice from '@/components/GasSaverNotice.vue'

  export default Vue.extend({
    components: {
      InterestsAndRewards,
      PendleRewardSection,
      PortfolioValuation,
      EpochCountdown,
      PendleVestingTable,
      YoOverviewSection,
      LpOverviewSection,
      StakeOverviewSection,
      GasSaverNotice,
    },
    data() {
      return {
        yields: createState({ response: [] }),
        distributedRewards: createState({ response: [] }),
        pendleReward: createState(),
        walletBalances: createState({ response: [] }),
        stakingPoolBalances: createState({ response: [] }),
      }
    },
    computed: {
      pendleToken() {
        return Token.query()
          .where('chainId', this.$store.state.wallet.networkId)
          .findBy('name', 'PENDLE')
      },
      yts() {
        return YT.query().where('chainId', this.$store.state.wallet.networkId)
      },
      pendleAmmLps() {
        return PendleAmmLpToken.query().where('chainId', this.$store.state.wallet.networkId)
      },
      pendleLps() {
        return PendleLpToken.query().where('chainId', this.$store.state.wallet.networkId)
      },
      ots() {
        return OT.query().where('chainId', this.$store.state.wallet.networkId)
      },
      otLps() {
        return OtLpToken.query().where('chainId', this.$store.state.wallet.networkId)
      },
      stakingPools() {
        return StakingPool.query()
          .where('chainId', this.$store.state.wallet.networkId)
          .whereNot('address', '0x0')
      },
      walletYields() {
        return new QueryCollection(
          ...(this.yields.response
            ? this.yields.response.filter((_yield) => _yield.origin.source.className === 'Wallet')
            : [])
        )
      },
      allYields() {
        return [...this.yields.response, ...this.distributedRewards.response]
      },
      isMainnet() {
        return sdkChainId === 1
      },
      lpOverviewExpandable() {
        return this.isMainnet
      },
    },
    methods: {
      QueryCollection,
      TokenAmount,
      async fetchDistributedRewards() {
        const { signer, provider, address } = this.$store.getters['wallet/user']

        const tokenAmounts = await UniForkMarket.methods({
          signer,
          provider,
          chainId: sdkChainId,
        }).fetchClaimableRewardsFromOTMarkets(
          this.otLps.toArray().map((lp) => UniForkMarket.find(lp.address, sdkChainId)),
          address
        )

        const valuations = await new TokenAmountCollection(...tokenAmounts)
          .contract({ signer, provider })
          .fetchValuations()

        return tokenAmounts.map((tokenAmount, index) => ({
          origin: {
            token: {
              name: 'OT-LP',
              symbol: 'OT-LP',
              chainId: sdkChainId,
              tokenCategories: ['lp'],
            },
            source: new Wallet({ address }),
          },
          yield: {
            tokenAmount: TokenAmount.fromSdkTokenAmount(tokenAmount),
            valuation: valuations[index],
          },
          acquisitionMethod: 'distribution',
          yieldType: 'reward',
        }))
      },
      fetchWalletBalances() {
        const { signer, provider, address } = this.$store.getters['wallet/user']

        return new Promise((resolve, reject) => {
          Token.query()
            .where(
              'address',
              [this.yts, this.ots, this.pendleLps].flat().map((token) => token.address)
            )
            .contract({ signer, provider })
            .balancesOf(address)
            .then((balances) => {
              TokenAmountCollection.fromArray(balances)
                .contract({ signer, provider })
                .fetchValuations()
                .then((valuations) => {
                  resolve(
                    balances.map((balance, index) => ({
                      tokenAmount: balance,
                      valuation: new Valuation(valuations[index]),
                    }))
                  )
                })
            })
            .catch((error) => reject(error))
        })
      },
      fetchStakingPoolBalances() {
        const { signer, provider, address } = this.$store.getters['wallet/user']

        return new Promise((resolve, reject) => {
          const stakingPools = this.stakingPools

          stakingPools
            .contract({ signer, provider })
            .balancesOf(address)
            .then((tokenAmounts) => {
              new TokenAmountCollection(...tokenAmounts)
                .contract({ signer, provider })
                .fetchValuations()
                .then((valuations) => {
                  resolve(
                    tokenAmounts.map((tokenAmount, index) => {
                      return {
                        tokenAmount,
                        valuation: valuations[index],
                        source: stakingPools[index],
                      }
                    })
                  )
                })
            })
            .catch((error) => reject(error))
        })
      },
      fetchYields() {
        const { yts, pendleAmmLps, stakingPools, ots } = this
        const { signer, provider, address } = this.$store.getters['wallet/user']

        return new Promise((resolve, reject) => {
          Promise.all([
            yts.contract({ signer, provider }).fetchInterests(address),
            pendleAmmLps.contract({ signer, provider }).fetchInterests(address),
            stakingPools.contract({ signer, provider }).fetchClaimableYields(address),
            ots.contract({ signer, provider }).fetchRewards(address),
          ])
            .then((responses) => {
              const yields = responses
                .flat()
                .map((originYields) =>
                  originYields.yields.map((_yield) => ({
                    origin: originYields.origin,
                    ..._yield,
                  }))
                )
                .flat()
                .filter((yieldMeta) => yieldMeta.yield.formattedAmount() > 0)

              const tokenAmounts = yields.map((_yield) => _yield.yield)

              new Sdk({ signer, provider, chainId: sdkChainId })
                .fetchValuations(tokenAmounts, sdkChainId)
                .then((valuations) => {
                  resolve(
                    yields.map((_yield, index) => ({
                      ..._yield,
                      yield: {
                        tokenAmount: _yield.yield,
                        valuation: valuations[index],
                      },
                    }))
                  )
                })
            })
            .catch((error) => reject(error))
        })
      },
      async fetchPendleReward() {
        if (!this.isMainnet) return;

        const { signer, provider, address } = this.$store.getters['wallet/user']
        const chainId = 1
        const tokenAmount = await new PendleReward()
          .contract({ signer, provider, chainId })
          .fetchClaimableYield(address)
        return TokenAmount.fromSdkTokenAmount(tokenAmount)
      },
    },
    created() {
      new PromiseHandler(this.fetchDistributedRewards, this.distributedRewards).execute()
      new PromiseHandler(this.fetchYields, this.yields).execute()
      new PromiseHandler(this.fetchPendleReward, this.pendleReward).execute()
      new PromiseHandler(this.fetchWalletBalances, this.walletBalances).execute()
      new PromiseHandler(this.fetchStakingPoolBalances, this.stakingPoolBalances).execute()
    },
  })
</script>
