<template>
  <pp-section
    header="PENDLE Reward"
    tooltip="All PENDLE rewards earned will be consolidated here, distributed after the start of each epoch in batches."
  >
    <pp-card class="border">
      <pp-table>
        <tbody v-if="loading">
          <pp-tr v-for="n in 5" :key="n">
            <pp-th>
              <pp-skeleton-loader class="h-8 w-full"></pp-skeleton-loader>
            </pp-th>
          </pp-tr>
        </tbody>

        <tbody v-else>
          <pp-tr v-if="hasClaimable">
            <pp-td
              v-text="tokenAmount.token.symbol"
              :data-test="`${tokenAmount.token.symbol.toLowerCase()}-incentives-symbol`"
            ></pp-td>
            <pp-td class="text-water-600 text-sm" v-text="tokenAmount.token.protocol"></pp-td>
            <pp-td class="w-full"></pp-td>
            <pp-td
              align="right"
              class="font-medium"
              :data-test="`${tokenAmount.token.symbol.toLowerCase()}-incentives-claimable`"
            >
              <pp-formatted-number :value="tokenAmount.formattedAmount()"></pp-formatted-number>
            </pp-td>
          </pp-tr>

          <pp-tr v-else key="pp-no-yield">
            <pp-td colspan="100">No PENDLE reward available.</pp-td>
          </pp-tr>
        </tbody>
      </pp-table>

      <pp-card-actions class="border-t pt-4">
        <pp-btn
          :disabled="!hasClaimable"
          class="flex w-full"
          size="lg"
          :loading="loading || form.is('loading')"
          @click="claim"
        >
          Claim
        </pp-btn>
      </pp-card-actions>
    </pp-card>
  </pp-section>
</template>

<script>
  import Vue from 'vue'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import PendleReward from '@/domains/entities/PendleReward'
  import { generateEtherscanTxUrl } from '@/assets/helpers'

  export default Vue.extend({
    props: {
      tokenAmount: { type: Object },
      loading: { type: Boolean, default: false },
    },
    data() {
      return {
        form: createState(),
      }
    },
    methods: {
      claim() {
        const { signer, provider, address } = this.$store.getters['wallet/user']
        new PromiseHandler(
          () =>
            new Promise((resolve, reject) => {
              const chainId = 1
              new PendleReward()
                .contract({ signer, provider, chainId })
                .claim(address)
                .then((response) => {
                  response.wait(1).then(() => setTimeout(() => resolve(response), 2000))
                })
                .catch((error) => reject(error))
            }),
          this.form
        )
          .execute()
          .then((response) => {
            this.$notification.success({
              title: 'Claim',
              text: 'Claimed PENDLE Rewards',
              action: {
                url: generateEtherscanTxUrl(response.hash),
                urlText: 'View on explorer',
              }
            })

            this.$emit('success', response)
          })
          .catch(() => {
            this.$notification.error({
              title: 'Claim',
              text: 'Unable to claim PENDLE Rewards',
            })
          })
      },
    },
    computed: {
      hasClaimable() {
        return this.tokenAmount?.rawAmount() > 0
      },
    },
  })
</script>
