import Token from './Token'
import PendleAmmLpTokenCollection from '../collections/PendleAmmLpTokenCollection'
import PendleAmmMarket from './PendleAmmMarket'

export default class PendleAmmLpToken extends Token {
  static query() {
    return new PendleAmmLpTokenCollection(
      ...Token.query()
        .where('tokenCategories', (val) => (val || []).includes('lp'))
        .where(
          'address',
          PendleAmmMarket.query().map((market) => market.address)
        )
    )
  }
}
