<template>
  <pp-section header="Stake Overview">
    <pp-card class="overflow-auto">
      <pp-table class="min-w-[600px]">
        <thead>
          <pp-th>Name</pp-th>
          <pp-th align="right">Staked</pp-th>
          <pp-th align="right">Reward</pp-th>
          <pp-th v-if="isMainnet" filler class="hidden lg:table-cell"></pp-th>
        </thead>

        <tbody v-if="loading">
          <pp-tr>
            <pp-td colspan="100">
              <pp-skeleton-loader class="h-8"></pp-skeleton-loader>
            </pp-td>
          </pp-tr>
        </tbody>

        <tbody v-else>
          <pp-tr v-if="balances.length === 0">
            <pp-td colspan="100">No balance available.</pp-td>
          </pp-tr>

          <pp-tr
            v-else
            v-for="balance in balances"
            :key="balance.token.address"
            :class="{
              'text-p-yellow-800': balance.composition.find((pool) => pool.source.disabled),
            }"
            :data-test="`${balance.tokenAmount.token.address}`"
          >
            <pp-td>
              <pp-token-preview :token="balance.token">
                <template #status-indicator>
                  <pp-market-status-indicator
                    v-if="balance.token.name !== 'PENDLE'"
                    class="ml-2"
                    :address="balance.token.address"
                  ></pp-market-status-indicator>
                </template>
              </pp-token-preview>

              <div v-if="balance.composition.find((pool) => pool.source.disabled)">
                <pp-pill size="xs" variant="accent">Old</pp-pill>
              </div>
            </pp-td>

            <pp-td align="right">
              <pp-formatted-price
                data-test="value"
                :currency="balance.valuation.currency"
                :value="balance.valuation.amount"
              ></pp-formatted-price>
              <div></div>
            </pp-td>

            <pp-td align="right">
              <div>
                <div
                  data-test="rewards"
                  v-for="yieldMeta in balance.yields"
                  :key="`${yieldMeta.origin.source.id}-${yieldMeta.yieldType}`"
                >
                  <pp-token-amount :token-amount="yieldMeta.yield.tokenAmount"></pp-token-amount>
                </div>
              </div>
            </pp-td>

            <pp-td v-if="isMainnet">
              <pp-btn
                v-if="balance.token.name !== 'PENDLE'"
                tag="router-link"
                variant="fill"
                class="h-8 min-w-[90px]"
                :to="{
                  name: 'StakeIndex',
                  params: { inputTokenAddress: balance.token.address },
                }"
              >
                Unstake
              </pp-btn>
            </pp-td>
          </pp-tr>
        </tbody>
      </pp-table>
    </pp-card>
  </pp-section>
</template>

<script>
  import Vue from 'vue'
  import Token from '@/domains/entities/Token'
  import TokenAmount from '@/domains/entities/TokenAmount'
  import Valuation from '@/domains/Valuation'
  import { sdkChainId } from '@/app-config/network'

  export default Vue.extend({
    props: {
      stakingPoolBalances: { type: Array, default: () => [] },
      yields: { type: Array, default: () => [] },
      loading: { type: Boolean, default: false },
    },
    computed: {
      balances() {
        const stakingPoolBalancesByToken = this.stakingPoolBalances.reduce((obj, balance) => {
          obj[balance.tokenAmount.token.address] = obj[balance.tokenAmount.token.address] || []
          obj[balance.tokenAmount.token.address].push(balance)
          return obj
        }, {})

        return Object.keys(stakingPoolBalancesByToken)
          .map((address) => {
            const token = Token.query().find(address)

            return {
              token,
              composition: stakingPoolBalancesByToken[address].filter(
                (balance) => balance.tokenAmount.rawAmount() > 0
              ),
              tokenAmount: stakingPoolBalancesByToken[address].reduce(
                (tokenAmount, balance) => tokenAmount.plus(balance.tokenAmount),
                new TokenAmount(token, 0)
              ),
              valuation: stakingPoolBalancesByToken[address].reduce(
                (valuation, balance) => valuation.plus(balance.valuation),
                new Valuation({ currency: 'USD', amount: 0 })
              ),
              yields: this.yields.filter(
                (yieldMeta) =>
                  yieldMeta.origin.source.className === 'StakingPool' &&
                  yieldMeta.origin.token.address === token.address &&
                  yieldMeta.yield.tokenAmount.rawAmount() > 0
              ),
            }
          })
          .filter((balance) => balance.tokenAmount.rawAmount() > 0)
      },
      isMainnet() {
        return sdkChainId === 1
      },
    },
  })
</script>
