<template>
  <div class="container mx-auto py-8">
    <DashboardContainer
      v-if="$store.state.wallet.address"
      @success="formSuccesses++"
      :key="formSuccesses"
    />
    <ConnectWalletScreen v-else />
  </div>
</template>

<script>
  import Vue from 'vue'
  import DashboardContainer from '@/components/dashboard/DashboardContainer.vue'
  import ConnectWalletScreen from '@/components/ConnectWalletScreen.vue'

  export default Vue.extend({
    components: {
      DashboardContainer,
      ConnectWalletScreen,
    },
    data() {
      return {
        formSuccesses: 0,
      }
    },
  })
</script>
