<template>
  <pp-btn @click="toggleSortType" v-bind="$attrs" icon variant="text" size="sm" type="button">
    <svg
      v-if="value.type === 'none'"
      xmlns="http://www.w3.org/2000/svg"
      class="h-full w-full"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z"
      />
    </svg>

    <svg
      v-else-if="value.type === 'ascending'"
      xmlns="http://www.w3.org/2000/svg"
      class="h-full w-full"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
        clip-rule="evenodd"
      />
    </svg>

    <svg
      v-else-if="value.type === 'descending'"
      xmlns="http://www.w3.org/2000/svg"
      class="h-full w-full"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
        clipRule="evenodd"
      />
    </svg>
  </pp-btn>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      value: {
        type: Object,
        required: true,
      },
    },
    methods: {
      toggleSortType() {
        const config = {
          none: 'ascending',
          ascending: 'descending',
          descending: 'none',
        }

        this.$emit('input', {
          ...this.value,
          type: config[this.value.type],
          activatedAt: config[this.value.type] === 'none' ? null : new Date(),
        })
      },
    },
  })
</script>
