<template>
  <div>
    <div class="text-left font-semibold text-2xl">Balance</div>
    <div class="mt-2 text-right">
      <pp-skeleton-loader
        v-if="loading"
        class="w-32 h-8 inline-block align-bottom"
      ></pp-skeleton-loader>

      <pp-formatted-number
        data-test="total-balance"
        v-else
        class="font-medium text-3xl"
        :humanize="false"
        :min-decimal="0"
        :max-decimal="2"
        :currency="totalValuation.currency"
        :value="totalValuation.amount"
      ></pp-formatted-number>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import BigNumberjs from 'bignumber.js'

  export default Vue.extend({
    props: {
      loading: { type: Boolean, default: false },
      yields: { type: Array, default: () => [] },
      wallet: { type: Array, default: () => [] },
      stakingPools: { type: Array, default: () => [] },
    },
    computed: {
      totalValuation() {
        const { yields, wallet, stakingPools } = this
        return {
          currency: 'USD',
          amount: [yields, wallet, stakingPools]
            .flat()
            .reduce((acc, item) => {
              return acc.plus(new BigNumberjs(item.valuation.amount))
            }, new BigNumberjs('0'))
            .toFixed(),
        }
      },
    },
  })
</script>
