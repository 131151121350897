<template>
  <pp-tr>
    <pp-td colspan="100" class="border-t-0">
      <div class="ml-auto w-96">
        <div v-if="liquidityInfo.is('loading')" class="space-y-2">
          <pp-skeleton-loader v-for="n in 2" :key="n" class="w-full h-8"></pp-skeleton-loader>
        </div>
        <template v-else-if="liquidityInfo.is('resolved')">
          <pp-simple-data>
            <template #left>
              Initial Capital Provided

              <pp-tooltip-trigger>
                Total amount added to the LP position. This value is only updated based on the
                capital at the time of liquidity provision / removal and does NOT change with price
                movements.
              </pp-tooltip-trigger>
            </template>

            <template #right>
              <pp-formatted-price
                class="font-medium"
                :currency="liquidityInfo.response.marketValuation.currency"
                :value="liquidityInfo.response.marketValuation.amount"
                data-test="initial-capital"
              ></pp-formatted-price>
            </template>
          </pp-simple-data>

          <pp-simple-data>
            <template #left>
              Yield Claimed

              <pp-tooltip-trigger>
                Total yield claimed from your YT component since liquidity provision.
              </pp-tooltip-trigger>
            </template>

            <template #right>
              <pp-formatted-price
                class="font-medium"
                :currency="liquidityInfo.response.yieldAccrued.valuation.currency"
                :value="liquidityInfo.response.yieldAccrued.valuation.amount"
                data-test="yield-accrued"
              ></pp-formatted-price>
            </template>
          </pp-simple-data>
        </template>

        <div v-if="liquidityInfo.is('rejected')">
          <pp-alert color="p-red">
            Unable to fetch data.

            <template v-slot:actions>
              <pp-btn variant="accent" color="p-red" @click="fetchLiquidityInfo">Retry</pp-btn>
            </template>
          </pp-alert>
        </div>
      </div>
    </pp-td>
  </pp-tr>
</template>

<script>
  import Vue from 'vue'
  import PendleLpToken from '@/domains/entities/PendleLpToken'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'

  export default Vue.extend({
    props: {
      token: { type: Object, required: true },
    },
    data() {
      return {
        liquidityInfo: createState(),
      }
    },
    computed: {
      lpToken() {
        return PendleLpToken.query().find(this.token.address)
      },
    },
    methods: {
      fetchLiquidityInfo() {
        new PromiseHandler(
          () => this.lpToken.liquidityInfoOf(this.$store.state.wallet.address),
          this.liquidityInfo
        ).execute()
      },
    },
    created() {
      this.fetchLiquidityInfo()
    },
  })
</script>
