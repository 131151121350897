<template>
  <pp-section header="PENDLE vesting">
    <template v-slot:header>
      <div class="flex items-center flex-wrap">
        <div>
          <div class="flex items-center flex-wrap">
            <h2 class="font-semibold text-2xl">Pendle Vesting</h2>

            <pp-tooltip-trigger class="ml-2">
              <div class="space-y-3">
                <p>
                  PENDLE rewards are vested over 5 epochs, 20% released at the end of each epoch.
                </p>
                <p>
                  Vested amount represents the total amount to be unlocked at the end of the epoch.
                </p>
                <p>
                  Accruing (est.) amount is the rewards accrued from staking in the current epoch.
                </p>
              </div>
            </pp-tooltip-trigger>
          </div>
          <div>
            Epoch ends in
            <pp-skeleton-loader
              v-if="currentEpochTime.is('loading')"
              class="inline-block w-24"
            ></pp-skeleton-loader>
            <pp-relative-time
              v-else-if="currentEpochTime.is('resolved')"
              class="font-semibold"
              format="countdown"
              :value="new Date(1000 * currentEpochTime.response)"
            ></pp-relative-time>
          </div>
        </div>

        <div class="flex-grow"></div>

        <slot name="right"></slot>
      </div>
    </template>

    <pp-card>
      <pp-table>
        <thead>
          <pp-tr>
            <pp-th>Epoch</pp-th>
            <pp-th align="right">Vested</pp-th>

            <pp-th align="right">
              <div>Accruing</div>
              <div class="text-right font-medium text-sm">Est.</div>
            </pp-th>
          </pp-tr>
        </thead>

        <tbody>
          <template v-if="vestings.is('loading')">
            <pp-tr v-for="n in 5" :key="`pp-key-${n}`">
              <pp-td v-for="n in 3" :align="n === 1 ? 'left' : 'right'" :key="n" class="py-2">
                <pp-skeleton-loader class="w-12 inline-block"></pp-skeleton-loader>
              </pp-td>
            </pp-tr>
          </template>

          <pp-tr v-else-if="vestings.is('rejected')">
            <pp-td colspan="100">
              <pp-alert color="p-red">
                Unable to load data.

                <template v-slot:actions>
                  <pp-btn variant="accent" color="p-red" @click="executeFetchVesting">Retry</pp-btn>
                </template>
              </pp-alert>
            </pp-td>
          </pp-tr>

          <pp-tr
            v-else-if="vestings.is('resolved')"
            v-for="vesting in vestings.value"
            :key="vesting.epochId"
          >
            <pp-td
              class="py-2"
              v-text="vesting.epochId"
              :data-test="`epoch-number-${vesting.epochId}`"
            ></pp-td>
            <pp-td class="py-2" align="right">
              <pp-formatted-number
                class="font-medium"
                :value="vesting.vested"
                :data-test="`epoch-${vesting.epochId}-vested`"
                :humanize="false"
              ></pp-formatted-number>
            </pp-td>
            <pp-td class="py-2 text-water-600" align="right">
              +
              <pp-formatted-number
                :value="vesting.accruing"
                :data-test="`epoch-${vesting.epochId}-accruing`"
                :humanize="false"
              ></pp-formatted-number>
            </pp-td>
          </pp-tr>
        </tbody>
      </pp-table>
    </pp-card>
  </pp-section>
</template>

<script>
  import Vue from 'vue'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import Token from '@/domains/entities/Token'
  import TokenAmount from '@/domains/entities/TokenAmount'
  import { getCurrentEpochEndTime } from '@pendle/sdk'
  import { sdkChainId } from '@/app-config/network'

  export default Vue.extend({
    props: {
      stakingPools: { type: Array, required: true },
    },
    data() {
      return {
        vestings: createState({ value: [] }),
        currentEpochTime: createState({ response: 0 }),
      }
    },
    computed: {
      pendleToken() {
        return Token.query()
          .where('chainId', this.$store.state.wallet.networkId)
          .findBy('name', 'PENDLE')
      },
    },
    methods: {
      getCurrentEpochEndTime() {
        const { signer } = this.$store.getters['wallet/identity']
        return new PromiseHandler(
          () => getCurrentEpochEndTime(signer, sdkChainId),
          this.currentEpochTime
        ).execute()
      },
      fetchVestings() {
        const { signer, provider, address } = this.$store.getters['wallet/user']

        return Promise.all([
          this.stakingPools.contract({ signer, provider }).fetchVestedRewards(address),
          this.stakingPools.contract({ signer, provider }).fetchAccruingRewards(address),
        ])
      },
      executeFetchVesting() {
        new PromiseHandler(this.fetchVestings, this.vestings)
          .execute()
          .then(([vestedPools, accruingPools]) => {
            const vestedRewards = vestedPools.map((vestedPool) => vestedPool.vestedRewards).flat()
            const accruingRewards = accruingPools
              .map((accruingPool) => accruingPool.accruingRewards)
              .flat()

            const epochIds = [
              ...new Set(
                [vestedRewards, accruingRewards]
                  .map((rewards) => rewards.map((reward) => reward.epochId))
                  .flat()
                  .sort((a, b) => a - b)
              ),
            ]

            this.vestings.value = epochIds.map((epochId) => {
              const [vestedPendle, accruingPendle] = [vestedRewards, accruingRewards].map(
                (rewardType) =>
                  rewardType
                    .filter((reward) => reward.epochId === epochId)
                    .map((reward) => reward.rewards)
                    .flat()
                    .filter((tokenAmount) => tokenAmount.token.address === this.pendleToken.address)
                    .reduce(
                      (acc, tokenAmount) => acc.plus(tokenAmount),
                      new TokenAmount(this.pendleToken, 0)
                    )
                    .formattedAmount()
              )

              return {
                epochId,
                vested: vestedPendle,
                accruing: accruingPendle,
              }
            })
          })
      },
    },
    created() {
      this.executeFetchVesting()
      this.getCurrentEpochEndTime()
    },
  })
</script>
