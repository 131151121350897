import QueryCollection from '@/domains/QueryCollection'
import { Sdk } from '@pendle/sdk'
import TokenAmount from '@/domains/entities/TokenAmount'
import Valuation from '@/domains/Valuation'
import { sdkChainId } from '@/app-config/network'

export default class TokenAmountCollection extends QueryCollection {
  static fromArray(tokenAmounts) {
    return new TokenAmountCollection(...tokenAmounts)
  }

  contract({ signer, provider }) {
    const tokenAmounts = this.toArray().map((tokenAmount) =>
      TokenAmount.fromSdkTokenAmount(tokenAmount)
    )

    return {
      fetchValuations: () =>
        new Promise((resolve, reject) => {
          new Sdk({ signer, provider, chainId: sdkChainId })
            .fetchValuations(tokenAmounts, sdkChainId)
            .then((responses) => {
              resolve(responses.map((response) => new Valuation(response)))
            })
            .catch((error) => {
              reject(error)
            })
        }),
    }
  }
}
