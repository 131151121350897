var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-wrap"},[_c('GasSaverNotice'),_c('div',{staticClass:"w-full lg:w-[320px] space-y-8"},[_c('PortfolioValuation',{attrs:{"loading":[_vm.walletBalances, _vm.stakingPoolBalances, _vm.yields, _vm.distributedRewards].some(function (query) { return query.is('loading'); }
        ),"wallet":_vm.walletBalances.response,"staking-pools":_vm.stakingPoolBalances.response,"yields":_vm.allYields.map(function (yieldItem) { return yieldItem.yield; })}}),_c('pp-card',[_c('pp-card-body',[_c('pp-simple-data',{staticClass:"-mt-2 text-lg !items-start",scopedSlots:_vm._u([{key:"left",fn:function(){return [_vm._v("PENDLE Price")]},proxy:true},{key:"right",fn:function(){return [_c('div',{staticClass:"text-right"},[_c('div',[_c('pp-token-amount-valuation',{staticClass:"font-medium",attrs:{"data-test":"token-price","token-amount":new _vm.TokenAmount(_vm.pendleToken, 1, false)}})],1),(_vm.pendleToken.sources[0])?_c('pp-obtain-token-link',{attrs:{"address":_vm.pendleToken.address}}):_vm._e()],1)]},proxy:true}])})],1)],1),(_vm.isMainnet)?_c('PendleRewardSection',{attrs:{"token-amount":_vm.pendleReward.response,"loading":_vm.pendleReward.is('first-load')},on:{"success":function($event){return _vm.$emit('success', $event)}}}):_vm._e(),_c('InterestsAndRewards',{attrs:{"yields":_vm.allYields,"loading":_vm.yields.is('first-load') && _vm.distributedRewards.is('first-load')},on:{"success":function($event){return _vm.$emit('success', $event)}}}),(_vm.isMainnet)?_c('EpochCountdown'):_c('PendleVestingTable',{attrs:{"staking-pools":_vm.stakingPools}})],1),_c('div',{staticClass:"w-full mt-8 lg:mt-0 lg:ml-8 lg:flex-1 space-y-8"},[_c('YoOverviewSection',{attrs:{"header":"YT Overview","balances":_vm.QueryCollection.from(_vm.walletBalances.response).where(
          'tokenAmount.token.tokenCategories',
          function (val) { return val.includes('yt'); }
        ),"yields":_vm.walletYields,"loading":_vm.walletBalances.is('loading')}}),_c('YoOverviewSection',{attrs:{"header":"OT Overview","yields":_vm.walletYields,"balances":_vm.QueryCollection.from(_vm.walletBalances.response).where(
          'tokenAmount.token.tokenCategories',
          function (val) { return val.includes('ot'); }
        ),"loading":_vm.walletBalances.is('loading')}}),_c('LpOverviewSection',{attrs:{"wallet-balances":_vm.walletBalances.response,"staking-pool-balances":_vm.stakingPoolBalances.response,"yields":_vm.yields.response,"tokens":_vm.pendleLps,"loading":_vm.walletBalances.is('loading'),"expandable":_vm.lpOverviewExpandable}}),_c('StakeOverviewSection',{attrs:{"staking-pool-balances":_vm.stakingPoolBalances.response,"yields":_vm.yields.response,"loading":_vm.stakingPoolBalances.is('loading')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }