<template>
  <pp-section
    header="Incentives & Interests"
    tooltip="Liquidity incentives and accrued interest currently unlocked and ready to be claimed."
  >
    <pp-card class="border">
      <pp-table>
        <tbody v-if="loading">
          <pp-tr v-for="n in 5" :key="n">
            <pp-th>
              <pp-skeleton-loader class="h-8 w-full"></pp-skeleton-loader>
            </pp-th>
          </pp-tr>
        </tbody>

        <tbody v-else>
          <pp-tr v-if="groupedYields.length === 0" key="pp-no-yield">
            <pp-td colspan="100">No incentive & interest available.</pp-td>
          </pp-tr>

          <pp-tr v-else v-for="tokenAmount in groupedYields" :key="tokenAmount.token.address">
            <pp-td
              v-text="tokenAmount.token.symbol"
              :data-test="`${tokenAmount.token.symbol.toLowerCase()}-incentives-symbol`"
            ></pp-td>
            <pp-td class="text-water-600 text-sm" v-text="tokenAmount.token.protocol"></pp-td>
            <pp-td class="w-full"></pp-td>
            <pp-td
              align="right"
              class="font-medium"
              :data-test="`${tokenAmount.token.symbol.toLowerCase()}-incentives-claimable`"
            >
              <pp-formatted-number :value="tokenAmount.formattedAmount()"></pp-formatted-number>
            </pp-td>
          </pp-tr>
        </tbody>
      </pp-table>

      <pp-card-actions class="border-t pt-4">
        <pp-btn
          :disabled="claimableYields.length === 0"
          class="flex w-full"
          size="lg"
          :loading="loading"
          @click="openDialog = true"
        >
          Claim
        </pp-btn>

        <v-easy-dialog v-model="openDialog" width="925px">
          <template v-slot="{ deactivate }">
            <ClaimYieldsForm
              @success="handleClaimSuccess"
              :deactivate="deactivate"
              :yields="claimableYields"
              :balance-threshold.sync="balanceThreshold"
            />
          </template>
        </v-easy-dialog>
      </pp-card-actions>
    </pp-card>
  </pp-section>
</template>

<script>
  import Vue from 'vue'
  import TokenAmount from '@/domains/entities/TokenAmount'
  import VEasyDialog from 'v-easy-dialog'
  import ClaimYieldsForm from '@/components/forms/ClaimYieldsForm.vue'

  export default Vue.extend({
    components: {
      VEasyDialog,
      ClaimYieldsForm,
    },
    props: {
      yields: { type: Array, default: () => [] },
      loading: { type: Boolean, default: false },
    },
    data() {
      return {
        openDialog: false,
        balanceThreshold: '0',
      }
    },
    methods: {
      handleClaimSuccess(event) {
        this.openDialog = false
        this.$emit('success', event)
      },
    },
    computed: {
      groupedYields() {
        return Object.values(
          this.yields.reduce((acc, _yield) => {
            acc[_yield.yield.tokenAmount.token.address] =
              acc[_yield.yield.tokenAmount.token.address] || []
            acc[_yield.yield.tokenAmount.token.address].push(_yield)
            return acc
          }, {})
        )
          .map((yields) =>
            yields.reduce(
              (acc, _yield) => acc.plus(_yield.yield.tokenAmount),
              new TokenAmount(yields[0].yield.tokenAmount.token, 0)
            )
          )
          .filter((tokenAmount) => tokenAmount.formattedAmount() > 0)
      },
      claimableYields() {
        return this.yields
          .filter((_yield) => _yield.yield.tokenAmount.rawAmount() > 0)
          .filter(
            (_yield) =>
              _yield.yield.valuation.amount >
              parseFloat(this.balanceThreshold == 0 ? 0.01 : this.balanceThreshold)
          )
      },
    },
  })
</script>
