import { PendleMerkleDistributor } from '@pendle/sdk'
import { sdkChainId } from '@/app-config/network'

export default class PendleMerkleDistributorContract {
  constructor({ signer, provider, chainId }) {
    this.signer = signer
    this.provider = provider
    this.chainId = chainId
  }

  fetchClaimableYield(userAddress) {
    return this.#sdkMethods().fetchClaimableYield(userAddress)
  }

  claim(userAddress) {
    return this.#sdkMethods().claim(userAddress)
  }

  #sdkMethods() {
    const distributor = new PendleMerkleDistributor()

    return distributor.methods({
      signer: this.signer,
      provider: this.provider,
      chainId: sdkChainId,
    })
  }
}
