<template>
  <pp-section :header="header">
    <pp-card class="overflow-auto">
      <pp-table class="min-w-[600px]">
        <thead>
          <pp-tr>
            <pp-th>Name</pp-th>
            <pp-th align="right">Balance</pp-th>
            <pp-th align="right">
              <div>Yield</div>
              <div class="font-normal text-sm">Accrued</div>
            </pp-th>
          </pp-tr>
        </thead>

        <tbody v-if="loading">
          <pp-tr>
            <pp-td colspan="100">
              <pp-skeleton-loader class="h-8"></pp-skeleton-loader>
            </pp-td>
          </pp-tr>
        </tbody>

        <tbody v-else>
          <pp-tr v-if="tokenYields.length === 0">
            <pp-td colspan="100">No balance available.</pp-td>
          </pp-tr>

          <pp-tr
            v-else
            v-for="tokenYield in tokenYields"
            :key="tokenYield.tokenAmount.token.address"
            :data-test="`${tokenYield.tokenAmount.token.address}`"
          >
            <pp-td>
              <pp-token-preview :token="tokenYield.tokenAmount.token"></pp-token-preview>
            </pp-td>
            <pp-td align="right">
              <pp-formatted-number
                data-test="quantity"
                class="font-medium"
                :value="tokenYield.tokenAmount.formattedAmount()"
                :humanize="false"
              ></pp-formatted-number>

              <div class="text-sm text-water-600">
                ~
                <pp-formatted-price
                  data-test="value"
                  :currency="tokenYield.valuation.currency"
                  :value="tokenYield.valuation.amount"
                ></pp-formatted-price>
              </div>
            </pp-td>

            <pp-td align="right">
              <div
                v-for="yieldMeta in tokenYield.yields"
                :key="yieldMeta.yield.tokenAmount.token.address"
              >
                <pp-token-amount
                  :token-amount="yieldMeta.yield.tokenAmount"
                  data-test="yield-quantity"
                ></pp-token-amount>

                <div class="text-water-600 text-sm">
                  <pp-formatted-price
                    data-test="yield-value"
                    :currency="yieldMeta.yield.valuation.currency"
                    :value="yieldMeta.yield.valuation.amount"
                  ></pp-formatted-price>
                </div>
              </div>
            </pp-td>
          </pp-tr>
        </tbody>
      </pp-table>
    </pp-card>
  </pp-section>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      header: { type: String, required: true },
      balances: { type: Array, default: () => [] },
      yields: { type: Array, default: () => [] },
      loading: { type: Boolean, default: false },
    },
    computed: {
      tokenYields() {
        return this.balances
          .filter((balance) => balance.tokenAmount.rawAmount() > 0)
          .map((balance) => ({
            ...balance,
            yields: this.yields.filter(
              (yieldMeta) => yieldMeta.origin.token.address === balance.tokenAmount.token.address
            ),
          }))
      },
    },
  })
</script>
