var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pp-section',{attrs:{"header":"LP Overview"}},[_c('pp-card',{staticClass:"overflow-auto"},[_c('pp-table',{staticClass:"min-w-[600px]"},[_c('thead',[_c('pp-th',[_vm._v("Name")]),_c('pp-th',{attrs:{"align":"right"}},[_vm._v("Balance")]),_c('pp-th',{attrs:{"align":"right"}},[_c('div',[_vm._v("Yield")]),_c('div',{staticClass:"text-sm font-normal"},[_vm._v("Accrued")])])],1),(_vm.loading)?_c('tbody',[_c('pp-tr',[_c('pp-td',{attrs:{"colspan":"100"}},[_c('pp-skeleton-loader',{staticClass:"h-8"})],1)],1)],1):_c('tbody',[(_vm.balances.length === 0)?_c('pp-tr',[_c('pp-td',{attrs:{"colspan":"100"}},[_vm._v("No balance available.")])],1):_vm._l((_vm.balancesMeta),function(balance){return [_c('pp-tr',{key:balance.tokenAmount.token.address,class:{
              'text-p-yellow-800': balance.composition.stakingPool.some(
                function (pool) { return pool.source.disabled; }
              ),
              'w-auto align-top outline-none transition focus:ring-2 ring-offset-2 ring-p-blue-600':
                _vm.expandable,
            },attrs:{"tabindex":_vm.expandable ? '1' : undefined,"role":_vm.expandable ? 'button' : undefined,"data-test":("" + (balance.tokenAmount.token.address))},on:{"click":function($event){balance.expanded = !balance.expanded},"keypress":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }balance.expanded = !balance.expanded},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }balance.expanded = !balance.expanded}]}},[_c('pp-td',[_c('pp-token-preview',{attrs:{"token":balance.tokenAmount.token,"show-indicator":true},scopedSlots:_vm._u([{key:"status-indicator",fn:function(){return [_c('pp-market-status-indicator',{staticClass:"ml-2",attrs:{"address":balance.tokenAmount.token.address}})]},proxy:true}],null,true)}),(balance.composition.stakingPool.some(function (pool) { return pool.source.disabled; }))?_c('div',[_c('pp-pill',{attrs:{"size":"xs","variant":"accent"}},[_vm._v("Old")])],1):_vm._e()],1),_c('pp-td',{attrs:{"align":"right"}},[_c('div',[_c('pp-formatted-price',{attrs:{"data-test":"value","currency":balance.valuation.currency,"value":balance.valuation.amount}})],1),_c('div',{staticClass:"text-sm opacity-70"},[_c('pp-formatted-number',{attrs:{"data-test":"percent-staked","value":((+balance.tokenAmount.formattedAmount() -
                      +balance.composition.wallet.tokenAmount.formattedAmount()) /
                      balance.tokenAmount.formattedAmount()) *
                    100}}),_vm._v(" % staked ")],1)]),_c('pp-td',{attrs:{"align":"right"}},[_c('div',{staticClass:"space-y-4"},_vm._l((balance.yields),function(_yield){return _c('div',{key:_yield.tokenAmount.token.address},[_c('pp-token-amount',{attrs:{"token-amount":_yield.tokenAmount,"data-test":"yield-quantity"}}),_c('div',{staticClass:"opacity-70 text-sm"},[_c('pp-formatted-price',{attrs:{"data-test":"yield-value","currency":_yield.valuation.currency,"value":_yield.valuation.amount}})],1)],1)}),0)])],1),(_vm.expandable)?_c('pp-scale-transition',{key:("expansion--" + (balance.tokenAmount.token.address)),attrs:{"origin":"left"}},[_c('keep-alive',[(balance.expanded)?_c('LpOverviewItemExpansion',{attrs:{"token":balance.tokenAmount.token}}):_vm._e()],1)],1):_vm._e()]})],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }