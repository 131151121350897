import Token from './Token'
import PendleLpTokenCollection from '../collections/PendleLpTokenCollection'
import Market from './Market'
import { PendleQuerySet } from '@pendle/subgraph-sdk'
import TokenAmount from '@/domains/entities/TokenAmount'
import Valuation from '@/domains/Valuation'
import { sdkChainId } from '@/app-config/network'

export default class PendleLpToken extends Token {
  static query() {
    return new PendleLpTokenCollection(
      ...Token.query()
        .where('tokenCategories', (val) => (val || []).includes('lp'))
        .where(
          'address',
          Market.query()
            .where('tokenPair', (tokens) =>
              ['yt', 'ot'].some((tokenCategory) =>
                tokens.some((token) => token.tokenCategories.includes(tokenCategory))
              )
            )
            .map((market) => market.address)
        )
        .map((token) => new PendleLpToken(token))
    )
  }

  liquidityInfoOf(walletAddress) {
    return new Promise((resolve, reject) => {
      new PendleQuerySet(sdkChainId)
        .userLiquidityInfo(this.address, walletAddress)
        .then((response) => {
          resolve({
            marketValuation: new Valuation({
              currency: 'USD',
              amount: response.lpMarketValue.toFixed(),
            }),
            pendleClaimed: {
              tokenAmount: new TokenAmount(
                Token.query().where('network', this.network).findBy('name', 'PENDLE'),
                response.pendleClaimedRaw.toFixed()
              ),
              valuation: new Valuation({
                currency: 'USD',
                amount: response.pendleClaimedUsd.toFixed(),
              }),
            },
            totalCapital: {
              provided: new TokenAmount(this, response.totalCapitalProvided.toFixed()),
              withdrawn: new TokenAmount(this, response.totalCapitalWithdrawn.toFixed()),
            },
            yieldAccrued: {
              // tokenAmount: new TokenAmount(yieldToken, response.yieldAccuredRaw.toFixed()),
              valuation: new Valuation({
                currency: 'USD',
                amount: response.yieldAccuredUsd.toFixed(),
              }),
            },
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
